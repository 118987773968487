<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="记录状态">
          <el-radio-group v-model="condition.rentState" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">借用中</el-radio-button>
            <!-- <el-radio-button label="8">申请归还</el-radio-button>
            <el-radio-button label="9">拒绝借出</el-radio-button> -->
            <el-radio-button label="10">已归还</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产名称/使用人姓名"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="assetInfo" slot-scope="scope">
          <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetInfo.assetName }}</el-button>
        </template>
        <template slot="rentFee" slot-scope="scope">
          <span class="padding-right-mini" v-if="scope.row.rentFee !== null">{{ scope.row.rentFee }}元</span>
          <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRentFee(scope.row)"></el-button>
        </template>
        <template slot="rentState" slot-scope="scope">
          <span v-if="scope.row.rentState == 0" class="text-blue">申请中</span>
          <span v-else-if="scope.row.rentState == 1" class="text-orange">借用中</span>
          <span v-else-if="scope.row.rentState == 8" class="text-brown">申请归还</span>
          <span v-else-if="scope.row.rentState == 9" class="text-red">拒绝借出</span>
          <span v-else-if="scope.row.rentState == 10" class="text-green">已归还</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'RentList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产名称', slot: 'assetInfo' },
          { label: '责任人', prop: 'reponsibleUserName' },
          { label: '租借人', prop: 'rentUserName' },
          { label: '租借时间', prop: 'rentTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '归还时间', prop: 'returnTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '借前公里数', prop: 'rentMilage' },
          { label: '归还公里数', prop: 'returnMilage' },
          { label: '租借目的', prop: 'purpose' },
          { label: '租借费用', slot: 'rentFee' },
          { label: '状态', slot: 'rentState' }
        ]
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        rentState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/assetrent/list', ['enterpriseCode', this.condition])
    },
    editRentFee(data) {
      var t = Object.assign({}, data)
      this.$lgh.msgPrompt('请输入费用', (p) => {
        if (isNaN(parseFloat(p))) {
          this.$lgh.toastError('请输入有效费用数值')
          return
        }
        t.rentFee = parseFloat(p)

        this.$lgh.post('api/enterprise/assetrent/confirm/fee', t).then((r) => {
          this.$lgh.toastSuccess('修改费用信息成功')
          this.getPageData()
        })
      })
    }
  }
}
</script>
